import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import { AmazonContextProvider } from "./Contexts/AmazonContext";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
import "./index.css";

ReactDOM.render(
  <div>
    <AmazonContextProvider>
      <App />
    </AmazonContextProvider>
  </div>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register();
