import styled from "styled-components";
import { Link } from "react-router-dom";

export const Container = styled.div`
  position: relative;
  padding: 0 40px;

  @media only screen and (max-width: 600px) {
    padding: 0;
  }
`;

export const Header = styled.div`
  margin-bottom: 20px;
`;

export const ScrollContainer = styled.div`
  display: flex;
  overflow-x: auto;
  scroll-snap-type: x mandatory;
  -ms-overflow-style: none;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }
`;

export const BookCover = styled(Link)`
  flex: 0 0 auto;
  width: 200px;
  margin: 10px;
  scroll-snap-align: start;
  position: relative;
`;

export const CoverImage = styled.img`
  width: 100%;
  height: ${200 * (2250 / 1410)}px; // Maintain aspect ratio
  object-fit: cover;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: box-shadow 0.3s ease;

  &:hover {
    box-shadow: 0 10px 15px rgba(0, 0, 0, 0.2);
  }
`;

export const ScrollButton = styled.button`
  background: none;
  border: none;
  cursor: pointer;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 1;
  padding: 10px;
  color: #333;

  &:hover {
    color: #666;
  }

  &:focus {
    outline: none;
  }

  &.left {
    left: 0;
  }

  &.right {
    right: 0;
  }

  @media only screen and (max-width: 600px) {
    display: none;
  }
`;

export const ProgressBar = styled.div`
  position: absolute;
  bottom: 10px;
  left: 10px;
  right: 10px;
  height: 5px;
  background-color: rgba(255, 255, 255, 0.5);
  border-radius: 2.5px;
  overflow: hidden;
`;

export const Progress = styled.div`
  height: 100%;
  background-color: #4caf50;
  width: ${(props) => props.percent}%;
`;

export const BottomNavBar = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: space-around;
  align-items: center;
  background-color: #f8f9fa;
  padding: 10px 0;
  box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.1);
`;

export const NavButton = styled(Link)`
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #333;
  text-decoration: none;
  font-size: 12px;

  &:hover {
    color: #007bff;
  }
`;
