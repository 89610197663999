import React, { useState, useEffect } from "react";
import ColorThief from "colorthief";
import { useAmazonContext } from "../../Contexts/AmazonContext";
import ChapterProgressBarMini from "../ChapterProgressBarMini";

export default function CourseContentRow(props) {
  const {
    setSubContainer,
    setSubContainerEntries,
    courseDetails,
    courseTitleUserIsOn,
    chapterProgressData,
  } = useAmazonContext();

  const [textColor, setTextColor] = useState("");

  const [backgroundColor, setBackgroundColor] = useState("#dd6260");

  console.log(props.entries);

  useEffect(() => {
    if (props.dominantColor) {
      const luminance = calculateLuminance(props.dominantColor);
      setTextColor(luminance > 0.5 ? "#000" : "#fff");
    }
  }, [props.dominantColor]);

  const calculateLuminance = (color) => {
    const rgb = color.match(/\d+/g);
    const luminance = (0.299 * rgb[0] + 0.587 * rgb[1] + 0.114 * rgb[2]) / 255;
    return luminance;
  };

  const openRow = () => {
    setSubContainer(true);
    setSubContainerEntries(props.entries);
  };

  useEffect(() => {
    if (
      courseTitleUserIsOn &&
      chapterProgressData &&
      chapterProgressData.length > 0
    ) {
      const matchingCourse = chapterProgressData.find(
        (course) => course.courseProgress === courseTitleUserIsOn
      );

      if (
        matchingCourse &&
        matchingCourse.chapterProgress.includes(props.number)
      ) {
        setBackgroundColor("rgb(80, 200, 120)");
      } else {
        setBackgroundColor("#dd6260");
      }
    }
  }, [courseTitleUserIsOn, chapterProgressData]);

  return (
    <div
      className="sidenavRow"
      style={{
        cursor: "default",
        ...props.styleVariable,
        backgroundColor: props.dominantColor,
        opacity:
          props.lockState === "locked" && props.number !== "1.1" ? 0.6 : 1,
      }}
      onClick={() => props.entries && openRow()}
    >
      <div
        className="circle"
        style={{
          backgroundColor: backgroundColor,
          opacity:
            props.lockState === "locked" && props.number !== "1.1" ? 0.8 : 1,
        }}
      >
        <span className="circle-number">{props.number}</span>
      </div>

      {props.lockState === "locked" && props.number !== "1.1" ? (
        <div
          className="quizImage"
          style={{
            position: "relative",

            backgroundImage: `url(${props.firstSectionImage})`,
            backgroundPosition: "center",
            backgroundSize: "cover",
            borderRadius: "10%",
            float: "left",
            opacity: 0.8,
          }}
        >
          <img
            src="https://hhcourses-assets.s3.us-east-2.amazonaws.com/General/CanvaGeneratedImages/lockblack.png"
            alt=""
            style={{
              position: "absolute",
              left: 0,
              top: 0,
              right: 0,
              bottom: 0,
              display: "block",
              margin: "auto",
              width: "50px",
            }}
          ></img>
        </div>
      ) : (
        <div
          className="quizImage"
          style={{
            position: "relative",

            backgroundImage: `url(${props.firstSectionImage})`,
            backgroundPosition: "center",
            backgroundSize: "cover",
            borderRadius: "10%",
            float: "left",
          }}
        ></div>
      )}
      <div
        className="sideNavRowChapterContent"
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
        }}
      >
        {" "}
        {/* {courseDetails.courseTitle && (
        <img
          className="sideNavRowImage"
          src={
            "https://hhcourses-assets.s3.us-east-2.amazonaws.com/" +
            courseDetails.courseTitle.replace(/\s/g, "") +
            "/sectionImages/" +
            props.number +
            ".1.png"
          }
          alt=""
          crossOrigin="anonymous"
          style={{ opacity: props.lockState === "locked" ? 0.3 : 1 }}
        />
      )} */}
        <p
          className=""
          style={{
            color: textColor,
            fontSize: "2.2vh",
            fontWeight: "bold",
            marginRight: "35px",
            marginLeft: "10px",
            marginTop: "25px",
            width: "100%",
          }}
        >
          {props.title}
        </p>
      </div>
    </div>
  );
}
