import React, { useState, useEffect } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.bubble.css"; // Import Bubble theme styles
import ColorPicker from "./ColorPicker"; // Import the ColorPicker component
import "./LessonBuilder.css"; // Import your custom CSS file
import { FaGripLines, FaTrashAlt, FaCopy } from "react-icons/fa"; // Import drag, delete, and duplicate icons

// Define a custom toolbar with additional options
const modules = {
  toolbar: [
    [{ header: "1" }, { header: "2" }, { font: [] }],
    [{ size: [] }], // Quill will populate this with default size options
    [{ color: [] }, { background: [] }], // Quill will populate these with color pickers
    [{ align: [] }], // Quill will populate this with alignment options
    ["bold", "italic", "underline", "strike"], // Added underline and strike
    // Added subscript and superscript
    [{ list: "ordered" }, { list: "bullet" }],
    ["link"], // Added video
    ["code-block"], // Added code block
    // Added text direction
    ["clean"], // Clean formatting
  ],
  clipboard: {
    matchVisual: false,
    matchers: [
      ["p", customPasteHandler],
      ["span", customPasteHandler],
      ["strong", customPasteHandler],
      ["em", customPasteHandler],
      ["u", customPasteHandler],
    ],
  },
};

function customPasteHandler(node, delta) {
  let plainText = "";
  delta.ops.forEach((op) => {
    if (op.insert && typeof op.insert === "string") {
      plainText += op.insert;
    }
  });

  // Remove all HTML tags and attributes
  plainText = plainText.replace(/<[^>]*>/g, "");

  // Create a new delta with only plain text
  return {
    ops: [{ insert: plainText }],
  };
}

const formats = [
  "header",
  "font",
  "size",
  "color",
  "background",
  "bold",
  "italic",
  "underline",
  "strike",
  "blockquote",
  "list",
  "bullet",
  "indent",
  "align",
  "link",
  "image",
  "video", // Added video
  "code-block", // Added code block
  "script", // Added subscript and superscript
  "direction", // Added text direction
];

// Utility function to convert RGB to hex
const rgbToHex = (r, g, b) => {
  const componentToHex = (c) => {
    const hex = c.toString(16);
    return hex.length === 1 ? "0" + hex : hex;
  };

  return `#${componentToHex(r)}${componentToHex(g)}${componentToHex(b)}`;
};

const TemplateEditor = ({
  template,
  onDelete,
  onSave,
  onDuplicate,
  dragHandleProps,
}) => {
  const [editTemplate, setEditTemplate] = useState(template);

  const handleContentChange = (subIndex, value, miniIndex = null) => {
    const newTemplate = { ...editTemplate };
    if (miniIndex !== null) {
      newTemplate.subSections[subIndex].miniSections[miniIndex].content = value;
    } else {
      newTemplate.subSections[subIndex].content = value;
    }
    setEditTemplate(newTemplate);
  };

  const handleLottieFilePathChange = (subIndex, value, miniIndex = null) => {
    const newTemplate = { ...editTemplate };
    if (miniIndex !== null) {
      newTemplate.subSections[subIndex].miniSections[miniIndex].lottieFilePath =
        value;
    } else {
      newTemplate.subSections[subIndex].lottieFilePath = value;
    }
    setEditTemplate(newTemplate);
  };

  const handleImageFilePathChange = (subIndex, value, miniIndex = null) => {
    const newTemplate = { ...editTemplate };
    if (miniIndex !== null) {
      newTemplate.subSections[subIndex].miniSections[miniIndex].imageFilePath =
        value;
    } else {
      newTemplate.subSections[subIndex].imageFilePath = value;
    }
    setEditTemplate(newTemplate);
  };

  const handleDelayChange = (subIndex, value, miniIndex = null) => {
    const newTemplate = { ...editTemplate };
    if (miniIndex !== null) {
      newTemplate.subSections[subIndex].miniSections[
        miniIndex
      ].transition.delay = value;
    } else {
      newTemplate.subSections[subIndex].transition.delay = value;
    }
    setEditTemplate(newTemplate);
  };

  const handleMaxWidthChange = (subIndex, value, miniIndex = null) => {
    const newTemplate = { ...editTemplate };
    if (miniIndex !== null) {
      newTemplate.subSections[subIndex].miniSections[miniIndex].style.maxWidth =
        value;
    } else {
      newTemplate.subSections[subIndex].style.maxWidth = value;
    }
    setEditTemplate(newTemplate);
  };

  const handleWidthChange = (subIndex, value, miniIndex = null) => {
    const newTemplate = { ...editTemplate };
    if (miniIndex !== null) {
      newTemplate.subSections[subIndex].miniSections[miniIndex].style.width =
        value;
    } else {
      newTemplate.subSections[subIndex].style.width = value;
    }
    setEditTemplate(newTemplate);
  };

  const handleBackgroundColorChange = (color) => {
    const newTemplate = { ...editTemplate };
    const hexColor = rgbToHex(color.r, color.g, color.b);
    newTemplate.style.backgroundColor = hexColor;
    setEditTemplate(newTemplate);
  };

  const handleSubSectionBackgroundColorChange = (subIndex, color) => {
    const newTemplate = { ...editTemplate };
    const hexColor = rgbToHex(color.r, color.g, color.b);
    newTemplate.subSections[subIndex].style.backgroundColor = hexColor;
    setEditTemplate(newTemplate);
  };

  const handleContentStyleBackgroundChange = (subIndex, color) => {
    const newTemplate = { ...editTemplate };
    const hexColor = rgbToHex(color.r, color.g, color.b);
    newTemplate.subSections[subIndex].contentStyle = {
      ...newTemplate.subSections[subIndex].contentStyle,
      background: hexColor,
    };
    setEditTemplate(newTemplate);
  };

  const handleAnimateBackgroundColorChange = (subIndex, color) => {
    const newTemplate = { ...editTemplate };
    const hexColor = rgbToHex(color.r, color.g, color.b);
    newTemplate.subSections[subIndex].animate = {
      ...newTemplate.subSections[subIndex].animate,
      background: hexColor,
    };
    setEditTemplate(newTemplate);
  };

  const handleSubSectionBorderColorAndContentColorChange = (
    subIndex,
    color
  ) => {
    const newTemplate = { ...editTemplate };
    const hexColor = rgbToHex(color.r, color.g, color.b);

    // Update the border color
    newTemplate.subSections[subIndex].style = {
      ...newTemplate.subSections[subIndex].style,
      border: `10px solid ${hexColor}`,
    };

    // Update the contentStyle color
    newTemplate.subSections[subIndex].contentStyle = {
      ...newTemplate.subSections[subIndex].contentStyle,
      color: hexColor,
    };

    setEditTemplate(newTemplate);
  };

  const extractBorderColor = (border) => {
    const result = border.match(
      /10px solid (#[0-9a-f]{6}|#[0-9a-f]{3}|rgb\(.+\))/i
    );
    return result ? result[1] : "#000000"; // default to black if no match
  };

  const handleMiniSectionBackgroundColorChange = (
    subIndex,
    miniIndex,
    color
  ) => {
    const newTemplate = { ...editTemplate };
    const hexColor = rgbToHex(color.r, color.g, color.b);

    // Update the background color of the specific miniSection
    newTemplate.subSections[subIndex].miniSections[miniIndex].style = {
      ...newTemplate.subSections[subIndex].miniSections[miniIndex].style,
      backgroundColor: hexColor,
    };

    setEditTemplate(newTemplate);
  };
  const handleVideoLinkChange = (subIndex, value) => {
    const newTemplate = { ...editTemplate };
    newTemplate.subSections[subIndex].videoLink = value;
    setEditTemplate(newTemplate);
  };

  useEffect(() => {
    onSave(editTemplate);
  }, [editTemplate]);

  const handleDelete = () => {
    onDelete();
  };

  const handleDuplicate = () => {
    onDuplicate(editTemplate);
  };

  return (
    <div className="template-editor-container">
      <div className="template-editor-content">
        {template.name === "HT1L1B" && (
          <ColorPicker
            color={editTemplate.style.backgroundColor || ""}
            onChange={handleBackgroundColorChange}
          />
        )}

        {template.name === "HT2B" && (
          <>
            <ColorPicker
              color={editTemplate.subSections[0].style.backgroundColor || ""}
              onChange={(color) =>
                handleSubSectionBackgroundColorChange(0, color)
              }
            />
            <ColorPicker
              color={editTemplate.subSections[2].style.backgroundColor || ""}
              onChange={(color) =>
                handleSubSectionBackgroundColorChange(2, color)
              }
            />
          </>
        )}

        {template.name === "VT3Highlight" && (
          <ColorPicker
            color={editTemplate.subSections[1].animate.background || ""}
            onChange={(color) => handleAnimateBackgroundColorChange(1, color)}
          />
        )}

        {template.name === "VT2BB" && (
          <>
            <ColorPicker
              color={editTemplate.subSections[0].contentStyle.background}
              onChange={(color) => handleContentStyleBackgroundChange(0, color)}
            />
            <ColorPicker
              color={editTemplate.subSections[1].contentStyle.background}
              onChange={(color) => handleContentStyleBackgroundChange(1, color)}
            />
          </>
        )}
        {template.name === "MVT1HL1T1B" && (
          <ColorPicker
            color={editTemplate.subSections[1].style.backgroundColor || ""}
            onChange={(color) =>
              handleSubSectionBackgroundColorChange(1, color)
            }
          />
        )}
        {template.name === "VT2B" && (
          <ColorPicker
            color={editTemplate.subSections[1].contentStyle.background}
            onChange={(color) => handleContentStyleBackgroundChange(1, color)}
          />
        )}
        {template.name === "VT2Definition" && (
          <ColorPicker
            color={editTemplate.subSections[1].style.backgroundColor || ""}
            onChange={(color) =>
              handleSubSectionBackgroundColorChange(1, color)
            }
          />
        )}
        {template.name === "Number Card" && (
          <ColorPicker
            color={extractBorderColor(editTemplate.subSections[0].style.border)}
            onChange={(color) =>
              handleSubSectionBorderColorAndContentColorChange(0, color)
            }
          />
        )}
        {template.name === "VT1Definition" && (
          <ColorPicker
            color={editTemplate.subSections[0].style.backgroundColor || ""}
            onChange={(color) =>
              handleSubSectionBackgroundColorChange(0, color)
            }
          />
        )}
        {template.name === "Three Things" &&
          editTemplate.subSections[0].miniSections.map(
            (miniSection, miniIndex) => (
              <div key={miniIndex}>
                <ColorPicker
                  color={miniSection.style.backgroundColor || ""}
                  onChange={(color) =>
                    handleMiniSectionBackgroundColorChange(0, miniIndex, color)
                  }
                />
              </div>
            )
          )}
        {template.name === "Three Things 2" &&
          editTemplate.subSections[1].miniSections.map(
            (miniSection, miniIndex) => (
              <div key={miniIndex}>
                <ColorPicker
                  color={miniSection.style.backgroundColor || ""}
                  onChange={(color) =>
                    handleMiniSectionBackgroundColorChange(1, miniIndex, color)
                  }
                />
              </div>
            )
          )}

        {template.name === "HT1I1B" && (
          <ColorPicker
            color={editTemplate.style.backgroundColor || ""}
            onChange={handleBackgroundColorChange}
          />
        )}

        {template.name === "HI1T1B" && (
          <ColorPicker
            color={editTemplate.style.backgroundColor || ""}
            onChange={handleBackgroundColorChange}
          />
        )}

        {template.name === "Definition with image" && (
          <ColorPicker
            color={editTemplate.style.backgroundColor || ""}
            onChange={handleBackgroundColorChange}
          />
        )}

        {editTemplate.subSections.map((sub, subIndex) => (
          <div key={subIndex}>
            {sub.miniSections && sub.miniSections.length > 0 ? (
              <div>
                {sub.miniSections.map((mini, miniIndex) => (
                  <div key={miniIndex}>
                    {mini.className === "LottieAnimation" ? (
                      <>
                        <div className="input-group">
                          <h5>Lottie file path</h5>
                          <input
                            type="text"
                            value={mini.lottieFilePath || ""}
                            onChange={(e) =>
                              handleLottieFilePathChange(
                                subIndex,
                                e.target.value,
                                miniIndex
                              )
                            }
                            placeholder="Edit Lottie File Path"
                          />
                        </div>
                        <div className="input-group">
                          <h5>Lottie size</h5>
                          <input
                            type="text"
                            value={mini.style?.width || ""}
                            onChange={(e) =>
                              handleWidthChange(
                                subIndex,
                                e.target.value,
                                miniIndex
                              )
                            }
                            placeholder="Edit Width"
                          />
                        </div>
                      </>
                    ) : mini.className === "sectionDescription image" ? (
                      <>
                        <div className="input-group">
                          <h5>Image file path</h5>
                          <input
                            type="text"
                            value={mini.imageFilePath || ""}
                            onChange={(e) =>
                              handleImageFilePathChange(
                                subIndex,
                                e.target.value,
                                miniIndex
                              )
                            }
                            placeholder="Edit Image File Path"
                          />
                        </div>
                        <div className="input-group">
                          <h5>Image size</h5>
                          <input
                            type="text"
                            value={mini.style?.maxWidth || ""}
                            onChange={(e) =>
                              handleMaxWidthChange(
                                subIndex,
                                e.target.value,
                                miniIndex
                              )
                            }
                            placeholder="Edit Max Width"
                          />
                        </div>
                      </>
                    ) : (
                      <>
                        <div className="reactquill-input-group">
                          <h5>Text</h5>
                          <ReactQuill
                            className="custom-quill-editor"
                            theme="bubble" // Use bubble theme
                            value={mini.content || ""}
                            onChange={(value) =>
                              handleContentChange(subIndex, value, miniIndex)
                            }
                            placeholder=""
                            modules={modules}
                            formats={formats}
                          />
                        </div>
                      </>
                    )}
                    <div className="input-group">
                      <h5>Delay</h5>
                      <input
                        type="number"
                        value={mini.transition?.delay || 0}
                        onChange={(e) =>
                          handleDelayChange(subIndex, e.target.value, miniIndex)
                        }
                        placeholder="Edit Delay"
                      />
                    </div>
                  </div>
                ))}
              </div>
            ) : (
              <div>
                {sub.className === "LottieAnimation" ? (
                  <>
                    <div className="input-group">
                      <h5>Lottie file path</h5>
                      <input
                        type="text"
                        value={sub.lottieFilePath || ""}
                        onChange={(e) =>
                          handleLottieFilePathChange(subIndex, e.target.value)
                        }
                        placeholder="Edit Lottie File Path"
                      />
                    </div>
                    <div className="input-group">
                      <h5>Lottie size</h5>
                      <input
                        type="text"
                        value={sub.style?.width || ""}
                        onChange={(e) =>
                          handleWidthChange(subIndex, e.target.value)
                        }
                        placeholder="Edit Width"
                      />
                    </div>
                  </>
                ) : sub.className === "sectionDescription image" ? (
                  <>
                    <div className="input-group">
                      <h5>Image file path</h5>
                      <input
                        type="text"
                        value={sub.imageFilePath || ""}
                        onChange={(e) =>
                          handleImageFilePathChange(subIndex, e.target.value)
                        }
                        placeholder="Edit Image File Path"
                      />
                    </div>
                    <div className="input-group">
                      <h5>Image size</h5>
                      <input
                        type="text"
                        value={sub.style?.maxWidth || ""}
                        onChange={(e) =>
                          handleMaxWidthChange(subIndex, e.target.value)
                        }
                        placeholder="Edit Max Width"
                      />
                    </div>
                  </>
                ) : sub.className === "YouTubeVideo" ? (
                  <input
                    type="text"
                    value={editTemplate.subSections[0].videoLink || ""}
                    onChange={(e) => handleVideoLinkChange(0, e.target.value)}
                    placeholder="Edit Video Link"
                  />
                ) : (
                  <>
                    <div className="reactquill-input-group">
                      <h5>Text</h5>
                      <ReactQuill
                        className="custom-quill-editor"
                        theme="bubble" // Use bubble theme
                        value={sub.content || ""}
                        onChange={(value) =>
                          handleContentChange(subIndex, value)
                        }
                        placeholder=""
                        modules={modules}
                        formats={formats}
                      />
                    </div>
                  </>
                )}
                {sub.className !== "YouTubeVideo" && (
                  <div className="input-group">
                    <h5>Delay</h5>
                    <input
                      type="number"
                      value={sub.transition?.delay || 0}
                      onChange={(e) =>
                        handleDelayChange(subIndex, e.target.value)
                      }
                      placeholder="Edit Delay"
                    />
                  </div>
                )}
              </div>
            )}
          </div>
        ))}
      </div>
      <div className="template-editor-buttons">
        <button className="template-editor-button" onClick={handleDelete}>
          <FaTrashAlt />
        </button>
        <button className="template-editor-button" onClick={handleDuplicate}>
          <FaCopy />
        </button>
      </div>
      <div className="template-editor-handle" {...dragHandleProps}>
        <FaGripLines />
      </div>
    </div>
  );
};

export default TemplateEditor;
