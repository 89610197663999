import React, { useState, useRef, useEffect } from "react";
import "./SidebarTemplatePreview.css";
import TemplatePreview from "./TemplatePreview"; // Ensure this path is correct based on your project structure
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars } from "@fortawesome/free-solid-svg-icons";

const SidebarTemplatePreview = ({ templates, onSelectTemplate }) => {
  const [isOpen, setIsOpen] = useState(false);
  const sidebarRef = useRef(null);

  const toggleSidebar = () => {
    setIsOpen(!isOpen);
  };

  const handleClickOutside = (event) => {
    if (sidebarRef.current && !sidebarRef.current.contains(event.target)) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <>
      {!isOpen && (
        <button onClick={toggleSidebar} className="sidebar-toggle">
          Add Content
        </button>
      )}
      <div ref={sidebarRef} className={`sidebar ${isOpen ? "open" : ""}`}>
        {isOpen && (
          <div className="sidebar-content">
            {JSON.parse(JSON.stringify(templates)).map((template, index) => (
              <div
                key={index}
                className="template-preview"
                onClick={() => onSelectTemplate(template)}
              >
                <div className="template-name">{template.name}</div>
                <div className="template-preview-container">
                  <TemplatePreview template={template} />
                </div>
              </div>
            ))}
          </div>
        )}
      </div>
    </>
  );
};

export default SidebarTemplatePreview;
