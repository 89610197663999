import React, { useEffect, useState } from "react";
import { motion } from "framer-motion";
import LottieAnimation from "../LottieAnimation";
import ReactHtmlParser from "react-html-parser";
import { useAmazonContext } from "../../Contexts/AmazonContext";

const TemplatePreview = ({ template }) => {
  const [isHovered, setIsHovered] = useState(false);
  const [key, setKey] = useState(0);
  const [initialHover, setInitialHover] = useState(true);
  const { isDarkMode } = useAmazonContext();

  useEffect(() => {
    let interval;
    if (isHovered) {
      if (initialHover) {
        setKey((prevKey) => prevKey + 1); // Trigger the first animation immediately
        setInitialHover(false); // Set initialHover to false after the first hover
      } else {
        interval = setInterval(() => {
          setKey((prevKey) => prevKey + 1); // Update key to restart animations
        }, 5000); // 5000ms = 5 seconds
      }
    } else {
      setInitialHover(true); // Reset initialHover when not hovered
    }
    return () => clearInterval(interval); // Cleanup interval on component unmount
  }, [isHovered, initialHover]);

  const removeAutoplay = (url) => {
    const urlObj = new URL(url);
    urlObj.searchParams.delete("autoplay");
    return urlObj.toString();
  };

  return (
    <div
      className="template-preview-container"
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      {template.className === "multiSection" ? (
        <motion.div
          key={`${key}-multiSection`} // Use key to restart animation
          className={template.className}
          style={template.style}
          initial={isHovered ? template.initial : template.animate}
          animate={isHovered ? template.animate : template.animate}
          exit={template.exit}
          transition={template.transition}
        >
          {template.subSections.map((subSection, subSectionIndex) => (
            <motion.div
              key={`${key}-${subSectionIndex}`} // Use key to restart animation
              id={`subSection-${subSectionIndex}`}
              className={subSection.className}
              style={subSection.style}
              initial={isHovered ? subSection.initial : subSection.animate}
              animate={isHovered ? subSection.animate : subSection.animate}
              exit={subSection.exit}
              transition={subSection.transition}
            >
              {subSection.miniSections.map((miniSection, miniSectionIndex) =>
                miniSection.className === "LottieAnimation" ? (
                  <LottieAnimation
                    key={`${key}-${miniSectionIndex}`} // Use key to restart animation
                    lottieFile={miniSection.lottieFilePath}
                    className={miniSection.className}
                    style={miniSection.style}
                    initial={
                      isHovered ? miniSection.initial : miniSection.animate
                    }
                    animate={
                      isHovered ? miniSection.animate : miniSection.animate
                    }
                    exit={miniSection.exit}
                    transition={miniSection.transition}
                    speed={miniSection.speed}
                    loop={miniSection.loop}
                  />
                ) : miniSection.className === "sectionDescription image" ? (
                  <motion.div
                    key={`${key}-${miniSectionIndex}`} // Use key to restart animation
                    className={`${miniSection.className} ${
                      isDarkMode ? "darkThemeFont" : "lightThemeFont"
                    }`}
                    style={miniSection.style}
                    initial={
                      isHovered ? miniSection.initial : miniSection.animate
                    }
                    animate={
                      isHovered ? miniSection.animate : miniSection.animate
                    }
                    exit={miniSection.exit}
                    transition={miniSection.transition}
                  >
                    <div style={miniSection.contentStyle}>
                      <img src={miniSection.imageFilePath} alt="noImage" />
                    </div>
                  </motion.div>
                ) : (
                  <motion.div
                    key={`${key}-${miniSectionIndex}`} // Use key to restart animation
                    className={`${miniSection.className} ${
                      isDarkMode ? "darkThemeFont" : "lightThemeFont"
                    }`}
                    style={miniSection.style}
                    initial={
                      isHovered ? miniSection.initial : miniSection.animate
                    }
                    animate={
                      isHovered ? miniSection.animate : miniSection.animate
                    }
                    exit={miniSection.exit}
                    transition={miniSection.transition}
                  >
                    <div style={miniSection.contentStyle}>
                      {ReactHtmlParser(miniSection.content)}
                    </div>
                  </motion.div>
                )
              )}
            </motion.div>
          ))}
        </motion.div>
      ) : (
        <motion.div
          key={`${key}-singleSection`} // Use key to restart animation
          className={template.className}
          style={template.style}
          initial={isHovered ? template.initial : template.animate}
          animate={isHovered ? template.animate : template.animate}
          exit={template.exit}
          transition={template.transition}
        >
          {template.subSections.map((subSection, subSectionIndex) =>
            subSection.className === "YouTubeVideo" ? (
              <div
                key={`${key}-${subSectionIndex}`} // Use key to restart animation
                style={subSection.style}
              >
                <div className="ratio ratio-16x9">
                  <iframe
                    src={removeAutoplay(subSection.videoLink)}
                    title="YouTube video"
                    allowFullScreen
                    style={{ width: "100%", height: "100%" }}
                  ></iframe>
                </div>
              </div>
            ) : subSection.className === "LottieAnimation" ? (
              <LottieAnimation
                key={`${key}-${subSectionIndex}`} // Use key to restart animation
                lottieFile={subSection.lottieFilePath}
                className={subSection.className}
                style={subSection.style}
                initial={isHovered ? subSection.initial : subSection.animate}
                animate={isHovered ? subSection.animate : subSection.animate}
                exit={subSection.exit}
                transition={subSection.transition}
                speed={subSection.speed}
                loop={subSection.loop}
              />
            ) : subSection.className === "sectionDescription image" ? (
              <motion.div
                key={`${key}-${subSectionIndex}`} // Use key to restart animation
                className={`${subSection.className} ${
                  isDarkMode ? "darkThemeFont" : "lightThemeFont"
                }`}
                style={subSection.style}
                initial={isHovered ? subSection.initial : subSection.animate}
                animate={isHovered ? subSection.animate : subSection.animate}
                exit={subSection.exit}
                transition={subSection.transition}
              >
                <div style={subSection.contentStyle}>
                  <img src={subSection.imageFilePath} alt="noImage" />
                </div>
              </motion.div>
            ) : (
              <motion.div
                key={`${key}-${subSectionIndex}`} // Use key to restart animation
                className={`${subSection.className} ${
                  isDarkMode ? "darkThemeFont" : "lightThemeFont"
                }`}
                style={subSection.style}
                initial={isHovered ? subSection.initial : subSection.animate}
                animate={isHovered ? subSection.animate : subSection.animate}
                exit={subSection.exit}
                transition={subSection.transition}
              >
                <div style={subSection.contentStyle}>
                  {ReactHtmlParser(subSection.content)}
                </div>
              </motion.div>
            )
          )}
        </motion.div>
      )}
    </div>
  );
};

export default TemplatePreview;
