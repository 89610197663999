import React, { useState, useContext, useEffect, useRef } from "react";
import { useAmazonContext } from "../Contexts/AmazonContext";
import axios from "axios";
import api from "./api";
import { motion } from "framer-motion";
import "../components/sidebar/ProgressBar.css";

const ChapterProgressBar = (props) => {
  const {
    courseTitleUserIsOn,
    entryStore,
    isDarkMode,
    subContainer,

    setCourseTitleUserIsOn,
    courseDetails,
    setCourseDetails,
    topicDatafromBackEnd,
    setTopicDatafromBackEnd,
    exampleDatafromBackEnd,
    setExampleDatafromBackEnd,

    setEntryStore,

    setIsDarkMode,
    progressData,
    setProgressData,
    chapterProgressData,
    setChapterProgressData,
  } = useAmazonContext();

  //Note the 1 in the dinominator takes into account that each chapter has one quiz (Total Lessons and Quzzies completed over total lessons and quizzes in the chapter)

  console.log(props.percentage);

  return (
    <div>
      <div
        className="quizProgressBarContainer"
        style={{ maxWidth: "100%", marginRight: "25px" }}
      >
        <div
          style={{
            flex: 1,
            backgroundColor: "#f0f0f0",
            borderRadius: 20,
          }}
        >
          <motion.div
            style={{
              width: `${props.percentage}%`,
              backgroundColor: "rgb(80, 200, 120)",
              height: "2vh",
              borderRadius: "inherit",
              textAlign: "center",
              lineHeight: "20px",
              color: "white",
            }}
            initial={{ width: -1 }}
            animate={{ width: `${props.percentage}%` }}
            transition={{ duration: 0.5, ease: "easeInOut" }}
          ></motion.div>
        </div>
      </div>
    </div>
  );
};

export default ChapterProgressBar;
