import React, { useState, useEffect } from "react";
import axios from "axios";
import api from "../api";
import styled from "styled-components";
import CourseIcon from "./CourseIcon";
import CourseStats from "./CourseStats";
import CourseCard from "./CourseCard";
import { Link } from "react-router-dom";
import Logout from "../userAuth/Logout";
import { useHistory } from "react-router-dom";
import { useAmazonContext } from "../../Contexts/AmazonContext";
import LottieAnimation from "../LottieAnimation";

const LottieContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100vw;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
  background-color: #fff;

  > div {
    width: 100%;
    max-width: 300px;

    @media only screen and (min-width: 601px) {
      max-width: 50%;
    }
  }
`;

function CourseMenuPage() {
  let { userRole, setUserRole } = useAmazonContext();
  const [coursesDisplayed, setCoursesDisplayed] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    api.get(`/coursesData`).then((response) => {
      setCoursesDisplayed(response.data);
      setIsLoading(false);
      console.log(response.data);
    });
  }, []);

  const history = useHistory();

  useEffect(() => {
    if (!sessionStorage.getItem("isLoggedIn")) {
      history.replace("/");
    }
  }, []);

  if (isLoading) {
    return (
      <div>
        <LottieContainer>
          <LottieAnimation
            lottieFile="https://hhcourses-assets.s3.us-east-2.amazonaws.com/General/LottieFiles/loading.json"
            loop={true}
          />
        </LottieContainer>
      </div>
    );
  }

  return (
    <div>
      <div className="sideNavChapterHeader">
        <p>Courses</p>
      </div>
      <div style={{ marginBottom: "50px" }}>
        <Logout />
      </div>
      {userRole === "admin" && (
        <Link to="/lesson-builder">
          <button>Go to Lesson Builder</button>
        </Link>
      )}
      <div className="courseCardsContainer">
        {coursesDisplayed.map((course, index) => (
          <React.Fragment key={index}>
            {(course.courseIsActive || userRole === "admin") && (
              <CourseCard course={course} />
            )}
          </React.Fragment>
        ))}
      </div>
    </div>
  );
}

export default CourseMenuPage;
