import React, { useEffect, useState } from "react";
import api from "../api";
import { useLocation, useHistory } from "react-router-dom";
import LottieAnimation from "../LottieAnimation";
import moment from "moment";
import { useAmazonContext } from "../../Contexts/AmazonContext";
const ReactNativeLoginHandling = ({ setIsLoggedIn, setTokenExpiration }) => {
  const location = useLocation();
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(true);
  const { userRole, setUserRole } = useAmazonContext();

  // Function to handle login
  const handleLogin = () => {
    // Perform login logic
    setIsLoggedIn(true);

    // Set expiration time to infinity
    const expirationTime = new Date("9999-12-31T23:59:59");
    setTokenExpiration(expirationTime);

    // Store isLoggedIn and expirationTime in localStorage
    localStorage.setItem("isLoggedIn", true);
    localStorage.setItem("tokenExpiration", expirationTime.getTime());

    // Check if there's a redirect URL in the query params
    const searchParams = new URLSearchParams(location.search);
    const redirectUrl = searchParams.get("redirect");

    if (redirectUrl) {
      history.push(redirectUrl);
    } else {
      history.push("/");
    }
  };

  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);
    const accessToken = searchParams.get("accessToken");

    if (accessToken) {
      console.log("Access token found:", accessToken);
      // Use the access token to authenticate the user
      api
        .get("https://www.googleapis.com/oauth2/v3/userinfo", {
          headers: { Authorization: `Bearer ${accessToken}` },
        })
        .then((res) => {
          const userObject = res.data;
          handleLogin();
          console.log(userObject);

          // Additional steps from CustomButton
          const formattedDate = moment().format("DD-MM-YYYY hh:mm:ss");
          console.log(formattedDate);

          const requestData = {
            userEmail: userObject.email,
            userFamilyName: userObject.family_name,
            userGivenName: userObject.given_name,
            userPicture: userObject.picture,
            loginDateTime: formattedDate,
          };
          console.log(requestData);

          localStorage.setItem("userEmail", userObject.email);
          console.log(localStorage.getItem("userEmail"));

          api
            .post("/insertLoginRecord", requestData)
            .then((response) => {
              if (response.status === 200) {
                return response.data;
              }
              throw new Error("Network response was not ok.");
            })
            .then((data) => {
              console.log(data);
            })
            .catch((error) => {
              if (
                error instanceof SyntaxError &&
                error.message.includes("Unexpected token 'U'")
              ) {
                return;
              }
              console.error("Error from fetch:", error);
            });

          const userEmail = localStorage.getItem("userEmail");
          if (!userEmail) {
            console.error("User email not found in local storage");
            return;
          }

          const fetchUserRole = async () => {
            try {
              const response = await api.get("/fetchUserRole", {
                params: {
                  userEmail: userEmail,
                },
              });
              setUserRole(response.data.role);
              localStorage.setItem("userRole", response.data.role);
              console.log(localStorage.getItem("userRole"));
            } catch (error) {
              console.error("Error fetching user role:", error);
              setUserRole("user");
              localStorage.setItem("userRole", "user");
              console.log(localStorage.getItem("userRole"));
            }
          };

          fetchUserRole();
        })
        .catch((error) => {
          console.error("Error authenticating with access token:", error);
        })
        .finally(() => {
          setIsLoading(false);
        });
    } else {
      setIsLoading(false);
    }
  }, []);

  if (isLoading) {
    return (
      <div>
        <LottieAnimation
          lottieFile="https://hhcourses-assets.s3.us-east-2.amazonaws.com/General/LottieFiles/loading.json"
          loop={true}
        />
      </div>
    );
  }

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
      }}
    >
      <div>Loading...</div>
    </div>
  );
};

export default ReactNativeLoginHandling;
