import React, { useEffect } from "react";

const LessonForm = ({ lesson, setLesson, topicHeaderDetails, topicNumber }) => {
  const handleChange = (e) => {
    const { name, value } = e.target;
    setLesson((prev) => ({ ...prev, [name]: value }));
  };

  useEffect(() => {
    if (topicHeaderDetails) {
      setLesson((prev) => ({
        ...prev,
        topicHeaderNumber: topicHeaderDetails.topicHeaderNumber || "",
        topicHeader: topicHeaderDetails.topicHeader || "",
      }));
    }
  }, [topicHeaderDetails, setLesson]);

  useEffect(() => {
    if (topicNumber) {
      setLesson((prev) => ({
        ...prev,
        topicNumber,
      }));
    }
  }, [topicNumber, setLesson]);

  const handleBlur = (e) => {
    const { name } = e.target;
    setLesson((prev) => ({
      ...prev,
      [`${name}IsEmpty`]: !prev[name],
    }));
  };

  return (
    <div>
      <div className="form-row">
        <div className="form-text">Topic</div>
        <input
          type="text"
          name="topicHeaderNumber"
          value={lesson.topicHeaderNumber || ""}
          onChange={handleChange}
          onBlur={handleBlur}
          readOnly
          className={`lesson-form-input number-input ${
            !lesson.topicHeaderNumber && "empty"
          }`}
        />
        <input
          type="text"
          name="topicHeader"
          value={lesson.topicHeader || ""}
          onChange={handleChange}
          onBlur={handleBlur}
          placeholder="Enter Topic Title"
          className={`lesson-form-input ${!lesson.topicHeader && "empty"}`}
        />
      </div>
      <div className="form-row">
        <span className="form-text">Chapter</span>
        <input
          type="text"
          name="topicNumber"
          value={lesson.topicNumber || ""}
          onChange={handleChange}
          onBlur={handleBlur}
          readOnly
          className={`lesson-form-input number-input ${
            !lesson.topicNumber && "empty"
          }`}
        />
        <input
          type="text"
          name="topicTitle"
          value={lesson.topicTitle}
          onChange={handleChange}
          onBlur={handleBlur}
          placeholder="Enter Chapter Title"
          className={`lesson-form-input ${!lesson.topicTitle && "empty"}`}
        />
      </div>
      <div className="form-row">
        <span className="form-text">Lesson</span>
        <input
          type="text"
          name="sectionNumber"
          value={lesson.sectionNumber}
          onChange={handleChange}
          onBlur={handleBlur}
          readOnly
          className={`lesson-form-input number-input ${
            !lesson.sectionNumber && "empty"
          }`}
        />
        <input
          type="text"
          name="sectionTitle"
          value={lesson.sectionTitle}
          onChange={handleChange}
          onBlur={handleBlur}
          placeholder="Enter Lesson Title"
          className={`lesson-form-input ${!lesson.sectionTitle && "empty"}`}
        />
      </div>
      <div className="form-row">
        <span className="form-text">Image</span>

        <input
          type="text"
          name="sectionImage"
          value={lesson.sectionImage}
          onChange={handleChange}
          onBlur={handleBlur}
          placeholder="Enter Lesson Image"
          className={`lesson-form-input ${!lesson.sectionImage && "empty"}`}
          style={{ width: "88%" }}
        />
      </div>
    </div>
  );
};

export default LessonForm;
