import React, { useState, useEffect } from "react";
import { useAmazonContext } from "../../Contexts/AmazonContext";
import CourseContentRow from "./CourseContentRow";
import styled from "styled-components";

import { Link } from "react-router-dom";
import axios from "axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";

import { motion } from "framer-motion";

const ChapterContainer = styled.div`
  @media only screen and (min-width: 601px) {
    width: 90%;
    margin-left: 5%;
  }
`;
export default function CourseContent(props) {
  const { isDarkMode } = useAmazonContext();

  let currentUrl = window.location.href;
  let currentUrlArray = currentUrl.split("/");
  let topicAndSection = currentUrlArray[currentUrlArray.length - 1];

  var topicAndSectionArray = topicAndSection.includes("%")
    ? topicAndSection.split("%23")
    : topicAndSection.split("#");

  let currentTopicNumber = topicAndSectionArray[0];

  return (
    <div
      className={`${isDarkMode ? "dark" : "light"}`}
      style={
        props.state === "exiting"
          ? { animation: "moveMainContainer .3s forwards" }
          : props.state === "entering"
          ? { animation: "moveMainContainer .3s reverse backwards" }
          : null
      }
    >
      <div className="sideNavContentsContainer">
        {props.newEntryStore &&
          props.newEntryStore.map((entry, index) => (
            <ChapterContainer key={index} className="sidenavContentChapter">
              <div className="sidenavContentHeaderMainMenu">
                {entry.topicHeaderNumber && `${entry.topicHeaderNumber}: `}
                {entry.title}
              </div>
              {entry.entries.map((subEntry, subIndex) => (
                <CourseContentRow
                  key={subIndex}
                  number={subEntry.topicNumber}
                  title={subEntry.title}
                  dominantColor={subEntry.dominantColor}
                  firstSectionImage={
                    subEntry.entries[0].entries[0].sectionImage
                  }
                  entries={subEntry.entries}
                  styleVariable={
                    subEntry.topicNumber === currentTopicNumber
                      ? { background: "#dfdfdf" }
                      : {}
                  }
                  lockState="unlocked"
                />
              ))}
            </ChapterContainer>
          ))}
        <div style={{ minHeight: "100px" }}></div>
      </div>
    </div>
  );
}
