import React, { useState, useContext, useEffect } from "react";

import styled, { css } from "styled-components";
// import { ButtonH2 } from "./Buttons";
import "../Quiz.css";
import { Link } from "react-router-dom";

import { useAmazonContext } from "../../Contexts/AmazonContext";

import SideNavRowLesson from "../sidebar/SideNavRowLesson";
import { motion } from "framer-motion";
import axios from "axios";
import api from "../api";

import LottieAnimation from "../LottieAnimation";

const ButtonH2 = styled.button`
  background: red;
  font-family: "Quicksand", sans-serif;
  padding: 7px 100px;
  text-transform: uppercase;
  font-size: 2vh;
  overflow: hidden;
  border: 0;
  border-radius: 5px;
  background: #967bb6;
  color: white;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.5);
  transition: all 0.25s ease;
  cursor: pointer;

  &:active {
    box-shadow: 0 2px 2px rgba(0, 0, 0, 0.5);
    background: #f2b1ae;
  }
`;

const ScoreCardBorder = styled.div`
  font-size: 40px;
  white-space: pre-line;
  margin: 10px 30px;
  box-shadow: 10px 15px 15px rgba(0, 0, 0, 0.767);
  display: flex;
  flex-wrap: wrap;
  position: relative;
  align-items: center;
  justify-content: space-around;
  /* text-align: center; */
  flex-direction: column;
  font-family: "Quicksand", sans-serif;
  border-radius: 1rem;
  padding: 10px;

  /* padding-left: 60px; */
`;

const ScoreCardText = styled.p`
  font-size: 5vh;
  font-family: "Quicksand", sans-serif;
  text-align: center;
  font-weight: 900;
  margin: 0;
  display: flex;
  justify-content: center;
  align-items: center;
`;
const ScoreMessage = styled.div`
  font-size: 2.5vh;
  text-align: center;
  margin: 10px;
  margin-bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  animation: ${(props) =>
    props.submitted && props.scorePercentage == 100
      ? css`
          shakeY 1s 1s
        `
      : props.submitted && props.scorePercentage < 100
      ? css`
          shakeX 1s 1s
        `
      : "none"};

  @media (max-width: 1200px) {
    flex-basis: 100%;
    margin-bottom: 0;
  }
`;

const ScoreCardButtons = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (max-width: 1200px) {
    flex-basis: 100%;
    margin-top: 30px;
  }
`;

const LottieContainer = styled.div`
  @media only screen and (min-width: 601px) {
    max-width: 50%;
  }
`;

const StartCard = (props) => {
  //Obtain Example Data from back end
  const { exampleDatafromBackEnd, courseTitleUserIsOn } = useAmazonContext();
  console.log(exampleDatafromBackEnd);

  // //When user changes quiz then set submitted back to false

  // useEffect(() => {
  //   setSubmitted(false);
  //   setSumCorrect([]);
  // }, [props.topicNumber]);

  // //End

  console.log(props.dominantColor);

  return (
    <div>
      {props.nextChapterFirstLessonNumber !== false && (
        <motion.div
          className="LessonExampleNextLessonBox"
          style={{
            marginTop: "0",
            marginBottom: "4vh",
            width: "100%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
          initial={{ opacity: 0, scale: 0.5 }}
          animate={{ opacity: 1, scale: [1, 1.05, 1] }}
          transition={{
            duration: 2,

            ease: [0, 0.71, 0.2, 1.01],
          }}
        >
          <ScoreCardBorder style={{ background: props.dominantColor }}>
            <ScoreMessage>
              <span
                style={{
                  fontSize: "3vh",
                  color: props.textColor,
                  fontWeight: "bold",
                }}
              >
                بِسْمِ ٱللَّٰهِ ٱلرَّحْمَٰنِ ٱلرَّحِيمِ
                <br /> In the Name of Allah, the Most Gracious, the Most
                Merciful
              </span>{" "}
              {/* Update chapter progress when score is 100 */}
            </ScoreMessage>
            <LottieContainer>
              <LottieAnimation
                lottieFile="https://hhcourses-assets.s3.us-east-2.amazonaws.com/General/LottieFiles/Man+reading+1.json"
                loop={true}
              ></LottieAnimation>
            </LottieContainer>

            <div>
              <motion.div
                style={{
                  fontSize: "2vh",
                  textAlign: "center",
                  color: props.textColor,
                }}
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{
                  delay: 1,
                  duration: 1,
                  ease: "easeInOut",
                }}
              >
                Chapter {props.nextChapterNumber}
              </motion.div>

              <motion.div
                style={{
                  fontSize: "4vh",
                  textAlign: "center",
                  marginBottom: "20px",
                  fontWeight: "bold",
                  color: props.textColor,
                }}
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{
                  delay: 2,
                  duration: 1.5,
                  ease: "easeInOut",
                }}
              >
                {props.nextChapterTitle}
              </motion.div>
            </div>
            <div style={{ width: "100%" }}>
              <Link
                to={`/${courseTitleUserIsOn}/lesson/${props.nextChapterFirstLessonNumber}`}
              >
                <motion.div
                  whileTap={{ scale: 0.8 }}
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  transition={{
                    type: "spring",
                    stiffness: 400,
                    width: "100%",
                    damping: 17,
                    opacity: { delay: 3.5, duration: 1.5, ease: "easeInOut" },
                  }}
                >
                  <SideNavRowLesson
                    number={props.nextChapterFirstLessonNumber}
                    title={props.nextChapterFirstLessonTitle}
                    image={props.nextChapterFirstLessonImage}
                    marginLeft="5%"
                    borderRadius="14% 2% 2% 14% / 50% 10% 10% 50%"
                  ></SideNavRowLesson>
                </motion.div>
              </Link>
            </div>
          </ScoreCardBorder>
        </motion.div>
      )}
    </div>
  );
};

export default StartCard;
