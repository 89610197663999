import React, { useState, useEffect } from "react";
import axios from "axios";
import api from "../api";

const CourseSelector = ({ setSelectedCourse }) => {
  const [courses, setCourses] = useState([]);

  useEffect(() => {
    api.get("/coursesData").then((response) => {
      setCourses(response.data);
    });
  }, []);

  const handleCourseSelect = (event) => {
    const selectedCourse = courses.find(
      (course) => course.courseNumber === event.target.value
    );
    setSelectedCourse(selectedCourse);
  };

  return (
    <div>
      <select onChange={handleCourseSelect}>
        <option value="">Select a course</option>
        {courses.map((course) => (
          <option key={course.courseNumber} value={course.courseNumber}>
            {course.courseTitle}
          </option>
        ))}
      </select>
    </div>
  );
};

export default CourseSelector;
