import React from "react";
import styled from "styled-components";
import { NavLink, useLocation } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCircle as faCircleRegular,
  faBookmark as faBookmarkRegular,
  faUser as faUserRegular,
} from "@fortawesome/free-regular-svg-icons";
import {
  faCircle as faCircleSolid,
  faBookmark as faBookmarkSolid,
  faUser as faUserSolid,
} from "@fortawesome/free-solid-svg-icons";

const StyledBottomNavBar = styled.nav`
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #ffffff;
  box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.1);
  display: flex;
  justify-content: space-around;
  padding: 10px 0;
  z-index: 1000;
`;

const NavItem = styled(NavLink)`
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #666;
  text-decoration: none;
  font-size: 12px;
  transition: color 0.3s ease;

  &.active {
    color: #8747d4;
  }

  svg {
    margin-bottom: 4px;
    font-size: 24px;
  }
`;

const BottomNavBar = () => {
  const location = useLocation();

  return (
    <StyledBottomNavBar>
      <NavItem to="/" isActive={() => location.pathname === "/"}>
        <FontAwesomeIcon
          icon={location.pathname === "/" ? faCircleSolid : faCircleRegular}
        />
        Home
      </NavItem>
      <NavItem to="/library" isActive={() => location.pathname === "/library"}>
        <FontAwesomeIcon
          icon={
            location.pathname === "/library"
              ? faBookmarkSolid
              : faBookmarkRegular
          }
        />
        Library
      </NavItem>
      <NavItem to="/profile" isActive={() => location.pathname === "/profile"}>
        <FontAwesomeIcon
          icon={location.pathname === "/profile" ? faUserSolid : faUserRegular}
        />
        Profile
      </NavItem>
    </StyledBottomNavBar>
  );
};

export default BottomNavBar;
