import React from "react";
import styled from "styled-components";

const ProgressBarWrapper = styled.div`
  width: 100%;
  padding: 10px 0;
`;

const ProgressBarContainer = styled.div`
  width: 50%;
  margin-left: 25%;
  background-color: #e0e0e0;
  border-radius: 10px;
  height: 20px;
  overflow: hidden;
  position: relative;
`;

const Progress = styled.div`
  height: 100%;
  width: ${(props) => props.percent}%;
  background-color: #4caf50;
  transition: width 0.3s ease;
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

const PercentageText = styled.span`
  color: black;
  font-size: 12px;
  font-weight: bold;
  padding-right: 5px;
  position: absolute;
  right: 5px;
  top: 50%;
  transform: translateY(-50%);
`;

const LibraryProgressBar = ({
  courseTitleUserIsOn,
  progressData,
  chapterProgressData,
  simplifiedChaptersLessonsData,
}) => {
  const calculateProgress = () => {
    if (
      !progressData ||
      !chapterProgressData ||
      !simplifiedChaptersLessonsData
    ) {
      return 0;
    }

    const courseProgressData = progressData.find(
      (progress) => progress.courseProgress === courseTitleUserIsOn
    );

    const courseChapterProgressData = chapterProgressData.find(
      (progress) => progress.courseProgress === courseTitleUserIsOn
    );

    if (!courseProgressData || !courseChapterProgressData) {
      return 0;
    }

    const completedLessons = new Set(courseProgressData.lessonProgress || []);
    const completedChapters = new Set(
      courseChapterProgressData.chapterProgress || []
    );

    let totalItems = 0;
    let completedItems = 0;

    simplifiedChaptersLessonsData.forEach((chapter) => {
      totalItems += chapter.lessons.length + 1; // +1 for the chapter quiz
      completedItems += chapter.lessons.filter((lesson) =>
        completedLessons.has(lesson.lessonNumber)
      ).length;
      if (completedChapters.has(chapter.chapterNumber)) {
        completedItems += 1; // Count completed chapter quiz
      }
    });

    return totalItems > 0 ? Math.round((completedItems / totalItems) * 100) : 0;
  };

  const progressPercentage = calculateProgress();

  return (
    <ProgressBarWrapper>
      <ProgressBarContainer>
        <Progress percent={progressPercentage} />
        <PercentageText>{progressPercentage}%</PercentageText>
      </ProgressBarContainer>
    </ProgressBarWrapper>
  );
};

export default LibraryProgressBar;
