import React from "react";
import styled from "styled-components";
import Logout from "../userAuth/Logout";
import { Link } from "react-router-dom";
import { useAmazonContext } from "../../Contexts/AmazonContext";
import BottomNavBar from "./BottomNavBar";
import { FaUser, FaEnvelope, FaCrown } from "react-icons/fa";

import {
  Header,
  ScrollContainer,
  BookCover,
  CoverImage,
  NavButton,
} from "./landingPageSharedStyledComponents";

const Container = styled.div`
  max-width: 600px;
  margin: 0 auto;
  padding: 20px;
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

const ProfileInfo = styled.div`
  background-color: #d2cfe6;
  padding: 20px;
  border-radius: 10px;
  margin: 0;
`;

const InfoItem = styled.div`
  font-size: 2.5vh;
  margin-bottom: 15px;
  display: flex;
  align-items: center;
  color: ${(props) =>
    props.isDarkMode ? "var(--font-dark)" : "var(--font-light)"};
  font-family: "Quicksand", sans-serif;

  svg {
    margin-right: 10px;
    color: #8747d4;
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 0 20px;
`;

const StyledButton = styled.button`
  padding: 10px 20px;
  font-size: 2.5vh;
  color: white;
  background-color: #8747d4;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  font-family: "Quicksand", sans-serif;
  font-weight: bold;

  &:hover {
    background-color: #7039b0;
  }
`;

const LessonBuilderButton = styled(StyledButton)`
  background-color: #eb8381;
  width: 100%;
  margin: 0;

  &:hover {
    background-color: #e05c5a;
  }
`;

const ToggleSwitch = styled.label`
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
  margin-top: 20px;
  input {
    opacity: 0;
    width: 0;
    height: 0;
  }

  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #eb8381;
    transition: 0.4s;
    border-radius: 34px;
  }

  .slider:before {
    position: absolute;
    content: "";
    height: 26px;
    width: 26px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    transition: 0.4s;
    border-radius: 50%;
  }

  input:checked + .slider {
    background-color: #b0240f;
  }

  input:checked + .slider:before {
    transform: translateX(26px);
    background-color: white;
  }
`;

const ThemeToggleContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  padding: 0 20px;
  width: 100%;
  margin: 0;
  margin-top: 20px;
`;

const ThemeLabel = styled.p`
  font-family: "Quicksand", sans-serif;
  font-size: 2.5vh;
  color: ${(props) =>
    props.isDarkMode ? "var(--font-dark)" : "var(--font-light)"};
  margin: 0;
  display: flex;
  align-items: center;
  height: 34px;
`;

function Profile() {
  const userEmail = localStorage.getItem("userEmail");
  let { userRole, isDarkMode, toggleMode } = useAmazonContext();

  return (
    <>
      <Header className="sideNavChapterHeader">
        <p>My Profile</p>
      </Header>
      <Container>
        <ProfileInfo>
          <InfoItem isDarkMode={isDarkMode}>
            <FaEnvelope /> {userEmail}
          </InfoItem>
          {userRole === "admin" && (
            <InfoItem isDarkMode={isDarkMode}>
              <FaCrown /> Admin privileges
            </InfoItem>
          )}
        </ProfileInfo>

        {userRole === "admin" && (
          <ButtonContainer>
            <Link
              to="/lesson-builder"
              style={{ textDecoration: "none", width: "100%" }}
            >
              <LessonBuilderButton>Go to Lesson Builder</LessonBuilderButton>
            </Link>
          </ButtonContainer>
        )}

        <ThemeToggleContainer>
          <ThemeLabel isDarkMode={isDarkMode}>Dark/Light Mode</ThemeLabel>
          <ToggleSwitch>
            <input type="checkbox" checked={isDarkMode} onChange={toggleMode} />
            <span className="slider"></span>
          </ToggleSwitch>
        </ThemeToggleContainer>
      </Container>

      <Logout />
      <div style={{ height: "100px" }} />
      <BottomNavBar />
    </>
  );
}

export default Profile;
